import * as React from 'react';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

const Path = (props) => (
	<motion.path
		fill="transparent"
		strokeWidth="3"
		stroke="#fff"
		strokeLinecap="round"
		// eslint-disable-next-line react/jsx-props-no-spreading
		{...props}
	/>
);

const MenuToggle = ({ toggle }) => (
	// eslint-disable-next-line react/button-has-type
	<button className = 'menu-btn' onClick={toggle}>
		<svg width="23" height="23" viewBox="0 0 23 23">
			<Path
				variants={{
					closed: { d: 'M 2 2.5 L 20 2.5' },
					open: { d: 'M 3 16.5 L 17 2.5' },
				}}
			/>
			<Path
				d="M 2 9.423 L 20 9.423"
				variants={{
					closed: { opacity: 1 },
					open: { opacity: 0 },
				}}
				transition={{ duration: 0.1 }}
			/>
			<Path
				variants={{
					closed: { d: 'M 2 16.346 L 20 16.346' },
					open: { d: 'M 3 2.5 L 17 16.346' },
				}}
			/>
		</svg>
	</button>
);

MenuToggle.defaultProps = {
	toggle: () => {},
};

MenuToggle.propTypes = {
	toggle: PropTypes.func,
};

export default MenuToggle;

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Header } from 'semantic-ui-react';
import { shuffle } from 'src/utils/utilsFunctions';
import Brand from 'src/Components/Brand';
// eslint-disable-next-line import/no-unresolved
import './Brands.less';


const Brands = ({ title  = "Marques" , data = [] }) => {
	const brands = useSelector((state) => state.brand.all);
	// eslint-disable-next-line no-unused-vars
	const [allBrands, setAllBrands] = useState([]);



	useEffect(() => {
		setAllBrands(shuffle(brands));
	}, [brands]);

	// console.log(allBrands?.filter( x => x?.data?.categorie?.toLowerCase() === 'divertissement' ))

	return (
		<div className="brands">
      <Header as="h3">{title}</Header>
      						<div style = {{ display : 'grid' , gap : '10px' , gridTemplateColumns : '1fr 1fr' , justifyContent : 'center' , marginBottom : 20 }}>

				{
					// allBrands.filter((_, index) => index < 20)				
					
					data.map((marque) => (
							<Brand
									brandImg={marque?.data?.logo?.data || marque?.data?.logo}
									brandName="swatch"
									brandId={marque.index}
									isPromo={marque.data.enPromotion}
								/>
					
					))}
              
              	</div>
	
		</div>
	);
};

export default Brands;
